.items {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  font-size: 0.9rem;
  overflow: hidden;
  padding: 0.2rem;
  position: relative;
  background: #303030;
  min-width: 200px;
  font-size: larger;
}

.item {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0.4rem;
  display: block;
  margin: 0;
  padding: 0.4rem 0.8rem;
  text-align: left;
  color: #fff;
  width: 100%;
}

.item.is-selected, .item:hover {
  background-color: #333B3D;
  color: #7CCCD9;
}