body {
  background-color: #303030;
  color: #fff;
}

.App {
  padding: 75px;
  display: grid;
  gap: 50px;
}

@media (max-width: 750px) {
  .App {
    padding: 20px;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 100;
  margin: 0;
  line-height: 100%;
  font-size: 250%;
}

code {
  display: inline;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  color: #303030;
  padding: 0.1em 0.2em;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

/* Tiptap */
.ProseMirror {
  border: 1px solid rgba(221, 221, 221, 0.222);
  border-radius: 5px;
  padding: 5px 20px;
  min-height: 60vh;
  margin-bottom: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  font-size: 20px;
}

.ProseMirror:focus {
  outline: none;
}

.ProseMirror code {
  display: inline;
}

.ProseMirror pre {
  background-color: #f5f5f5;
  color: #303030;
  padding: 20px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.ProseMirror hr {
  filter: opacity(0.4);
  -webkit-filter: opacity(0.4);
  -moz-filter: opacity(0.4);
  -ms-filter: opacity(0.4);
  -o-filter: opacity(0.4);
  opacity: 0.4;
  border: 0;
  border-top: 1px solid #f5f5f5;
}

.ProseMirror h1 {
  line-height: 175%;
  font-size: 200%;
}

.ProseMirror h2 {
  line-height: 175%;
  font-size: 175%;
}

.ProseMirror h3 {
  line-height: 175%;
  font-size: 150%;
}

.ProseMirror h4 {
  line-height: 175%;
  font-size: 125%;
}

.ProseMirror h5 {
  line-height: 175%;
  font-size: 100%;
}

.ProseMirror h6 {
  line-height: 175%;
  font-size: 75%;
}

.suggestion {
  color: #7CCCD9;
}